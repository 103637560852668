import { Component, EventEmitter, Output, ViewChild } from '@angular/core';
import {
  areaFieldConfigs,
  buildingsFieldConfigs,
  commonBuildingsFieldConfigs,
  connectToMapFieldConfigs,
  facilitiesFieldConfigs
} from './facilities-constant';
import { allFacilityDetails, facilities } from './facilityModels/facilityModels';
import { facilityPayload } from './facilityModels/facilities.model';
import { FacilitiesService } from './services/facilities.service';
import { Observable } from 'rxjs';
import { DevicesService } from '../../../add-device/services/devices.service';
import { WidgetsComponent } from 'src/app/shared/ui-components/widgets/widgets.component';
import { ButtonText, ControlName, SelectedTab } from 'src/app/shared/enums/settings.enum';
import { UtilityService } from 'src/app/shared/utility-services/utility.service';
import { ImageUploadComponent } from 'src/app/shared/ui-components/image-upload/image-upload.component';
import { MapViewComponent } from 'src/app/shared/ui-components/map-view/map-view.component';
import { GatewaysService } from '../gateways/services/gateways.service';
import { CampusService } from 'src/app/feature/campus/services/campus.service';
import { ImageEditorComponent } from 'src/app/shared/ui-components/image-editor/image-editor.component';
import { DialogService } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-facilities',
  templateUrl: './facilities.component.html',
  styleUrls: ['./facilities.component.scss']
})
export class FacilitiesComponent {
  @ViewChild('form') form!: any;
  @ViewChild(ImageUploadComponent) imageElement!: ImageUploadComponent;
  @ViewChild(WidgetsComponent) selectedEditRow!: WidgetsComponent;
  @ViewChild("mapComp") mapComp!: MapViewComponent;

  @Output() facilitiesDetails = new EventEmitter<allFacilityDetails>()

  facilityResponse!: allFacilityDetails;
  selectedFloor: any;
  btnText: string = ButtonText.addFacility;
  showFormSection: boolean = false;

  formConfig: any = []
  editRowData: any = []
  buttonConfig = {
    isDisable: true
  }
  imageEle: boolean = false;

  selectedTab: string = SelectedTab.facilities;
  imageFile!: any;
  widgetsConfig!: allFacilityDetails;
  facilityDropdownOptions: any = [];
  buildingDropdownOptions: any = [];
  isRegistrationView: boolean = false;
  deviceConfig: any = [];
  isDeviceRegister: string = '';
  tableConfig: any = [];
  editImages: string | File | undefined;
  showGeofence= false ;
  floorData: any;

  constructor(private facilityService: FacilitiesService, private utility: UtilityService,
    private campusService: CampusService,
    private devicesService: DevicesService,
    private gatewayService: GatewaysService,
    private dialogService: DialogService
  ) {
    this.tabChange(SelectedTab.facilities);
  }

  ngOnInit() {
    this.getWidgetDetail();
  }

  tabChange(tabName: string) {
    this.imageFile = null;
    this.editImages = '';
    this.isDeviceRegister = '';
    this.editRowData = null;
    this.isRegistrationView = false;
    if (this.tableConfig) {
      this.tableConfig.data = [];
    }
    if (this.selectedEditRow) {
      this.selectedEditRow.selectedRowIndex = -1;
    }
    this.showFormSection = false;
    this.selectedTab = tabName;
    this.imageEle = false;
    switch (tabName) {
      case SelectedTab.facilities:
        this.formConfig = [...facilitiesFieldConfigs, ...connectToMapFieldConfigs];
        this.btnText = ButtonText.addFacility
        break;
      case SelectedTab.buildings:
        this.formConfig = []
        this.formConfig = this.imageEle ? [...facilitiesFieldConfigs, ...commonBuildingsFieldConfigs, ...buildingsFieldConfigs]
          : [...facilitiesFieldConfigs, ...commonBuildingsFieldConfigs, ...connectToMapFieldConfigs];
        this.btnText = ButtonText.addBuilding
        let controlFacilityIndex = this.formConfig.findIndex((control: any) => control.name === ControlName.facility)
        if (controlFacilityIndex !== -1) {
          this.formConfig[controlFacilityIndex].options = [...this.facilityDropdownOptions];
        }
        break
      case SelectedTab.floors:
        this.formConfig = areaFieldConfigs;
        let controlBuildingIndex = this.formConfig.findIndex((control: any) => control.name === ControlName.building)
        if (controlBuildingIndex !== -1) {
          this.formConfig[controlBuildingIndex].options = [...this.buildingDropdownOptions];
        }
        this.imageEle = true;
        this.btnText = ButtonText.addFloor
        break;
    }
  }

  createForm() {
    this.showFormSection = true;
    setTimeout(() => {
      this.form.form.controls[ControlName.imageUpload]?.valueChanges.subscribe((change: boolean) => {
        this.imageEle = change;
        this.setFormControl();
      })
    }, 0);
  }

  setFormControl() {
    switch (this.selectedTab) {
      case SelectedTab.facilities:
        this.formConfig = this.imageEle ? [...facilitiesFieldConfigs] : [...facilitiesFieldConfigs, ...connectToMapFieldConfigs];
        break;
      case SelectedTab.buildings:
        this.formConfig = this.imageEle ? [...facilitiesFieldConfigs, ...commonBuildingsFieldConfigs, ...buildingsFieldConfigs]
          : [...facilitiesFieldConfigs, ...commonBuildingsFieldConfigs, ...connectToMapFieldConfigs];
        let controlFacilityIndex = this.formConfig.findIndex((control: any) => control.name === ControlName.facility)
        if (controlFacilityIndex !== -1) {
          this.formConfig[controlFacilityIndex].options = [...this.facilityDropdownOptions];
        }
        break
    }
  }

  getImageFile(image: File) {
    this.imageFile = image;
    this.editImages = ''
  }

  getWidgetDetail() {
    this.showGeofence =false;
    this.floorData = null;
    switch (this.selectedTab) {
      case SelectedTab.facilities:
        this.getFacilitiesWidgets();
        break;
      case SelectedTab.buildings:
        this.getBuildingsWidget();
        break;
      case SelectedTab.floors:
        this.getFloorsWidget();
        break;
    }
  }

  getFacilitiesWidgets() {
    this.facilityDropdownOptions = [];
    this.facilityService.getFacilities('').subscribe((res: allFacilityDetails) => {
      this.createTableConfig(res.data);
      res?.data.forEach((facility: facilities) => {
        this.facilityDropdownOptions.push(({ 'name': facility.title, 'id': facility.id }))
      });
    });
  }

  getBuildingsWidget() {
    this.buildingDropdownOptions = [];
    this.facilityService.getBuildings('').subscribe((res: allFacilityDetails) => {
      res?.data.forEach((building: facilities) => {
        this.buildingDropdownOptions.push(({ 'name': building.name, 'id': building.id }));
        let controlBuildingIndex = this.formConfig.findIndex((control: any) => control.name === ControlName.building);
        if (controlBuildingIndex !== -1) {
          this.formConfig[controlBuildingIndex].options = [...this.buildingDropdownOptions];
        }
      });
      if (this.selectedTab == SelectedTab.buildings) {
        this.createTableConfig(res.data);
      }
    })
  }

  getFloorsWidget() {
    this.facilityService.getFloors('').subscribe((res: allFacilityDetails) => {
      this.createTableConfig(res.data);
      if (!this.buildingDropdownOptions.length) {
        this.getBuildingsWidget();
      }
    })
  }

  public onSaveFacilityDetails(formConfig: any) {
    let payload: any = {
      image: this.imageFile,
      type: this.imageEle === true ? '1' : '2',
      latitude: formConfig?.value?.latitude,
      longitude: formConfig?.value?.longitude,
      map_type: formConfig?.value?.map_type,
      dx_position: formConfig?.value?.dxPositionX,
      dy_position: formConfig?.value?.dxPositionY,
      facility: formConfig?.value?.facility,
      point_of_interest: formConfig?.value?.point_of_interest,
      building: formConfig?.value?.building
    }
    if (formConfig.invalid) {
      formConfig.markAllAsTouched();
    } else {
      let titleField: string;
      switch (this.selectedTab) {
        case SelectedTab.facilities:
          titleField = 'title';
          break;
        case SelectedTab.buildings:
          titleField = 'name';
          break;
        case SelectedTab.floors:
          titleField = 'label';
          this.imageEle = true;
          break;
        default:
          return;
      }
      payload[titleField] = formConfig.value.title;
      if (this.editRowData?.id) {
        this.updateDetails(payload, formConfig);
      } else {
        this.addDetails(payload, formConfig);
      }
    }
  }

  // Add details......
  addDetails(payload: facilityPayload, formConfig: any) {

    let addWidgets: any;
    switch (this.selectedTab) {
      case SelectedTab.facilities:
        addWidgets = this.facilityService.addFacility(this.utility.toCreateFormData(payload));
        break;
      case SelectedTab.buildings:
        addWidgets = this.facilityService.addBuilding(this.utility.toCreateFormData(payload));
        break;
      case SelectedTab.floors:
        addWidgets = this.facilityService.addFloor(this.utility.toCreateFormData(payload));
        break;
      default:
        return;
    }
    addWidgets.subscribe((res: allFacilityDetails) => {
      if (formConfig) {
        formConfig.reset();
        if (this.selectedTab !== SelectedTab.floors) {
          formConfig.controls[ControlName.imageUpload].setValue(true);
        }
        // this.imageElement?.clear();
      }
      this.tableConfig.data.unshift(this.createItem(res.data));
      this.showFormSection = false;
    });
  }

  // Add details......
  updateDetails(payload: facilityPayload, formConfig?: any) {

    let updateWidgets: any;
    switch (this.selectedTab) {
      case SelectedTab.facilities:
        updateWidgets = this.facilityService.updateFacility(this.editRowData.id, this.utility.toCreateFormData(payload));
        break;
      case SelectedTab.buildings:
        updateWidgets = this.facilityService.updateBuilding(this.editRowData.id, this.utility.toCreateFormData(payload));
        break;
      case SelectedTab.floors:
        updateWidgets = this.facilityService.updateFloor(this.editRowData.id, this.utility.toCreateFormData(payload));
        if (this.mapComp?.registeredDataPoints?.length) {
          this.registerDevice();
        }
        this.isRegistrationView = false;
        break;
      default:
        return;
    }
    updateWidgets.subscribe((res: allFacilityDetails) => {
      if (formConfig) {
        formConfig.reset();
        if (this.selectedTab !== SelectedTab.floors) {
          formConfig.controls[ControlName.imageUpload].setValue(true);
        }
        // this.imageElement?.clear();
      }
      let data: any = { ...res.data };
      let updatedData = this.createItem(res.data);
      for (let i = 0; i < this.tableConfig.data.length; i++) {
        if (data.id == this.tableConfig.data[i].item.id) {
          this.tableConfig.data.splice(i, 1, updatedData);
        }
      }
      this.selectedEditRow.selectedRowIndex = -1
      this.showFormSection = false;
      this.editRowData = '';
    });
  }

  registerDevice() {
    const payload: any = {
      "items": this.mapComp.registeredDataPoints
    }
    if (this.isDeviceRegister == 'device') {
      this.devicesService.linkDeviceToFloor(payload).subscribe(res => {
      });
    } else {
      this.gatewayService.linkToFloor(payload).subscribe(res => {
      });
    }
    this.isDeviceRegister = '';
  }

  onEditDetail(data: facilities) {
    data['level'] = 'floor'
    this.selectedFloor = [data]
    if (this.selectedEditRow.selectedRowIndex === data.id) {
      this.form?.form.reset();
      this.isDeviceRegister = '';
      // this.imageElement?.clear();
      this.form?.form?.controls[ControlName.imageUpload]?.setValue(data?.type == 1 ? true : false);
      this.form?.form?.controls[ControlName.imageUpload]?.enable();
      this.createForm();
      this.editRowData = '';
      this.selectedEditRow.selectedRowIndex = -1;
      this.showFormSection = false
      this.isRegistrationView = false;
    } else {
      this.selectedEditRow.selectedRowIndex = data.id;
      this.showFormSection = true;
      this.editRowData = data;
      this.imageEle = data?.type == 1 ? true : false;
      if (this.isDeviceRegister == 'device') {
        this.getAllDevices();
      } else if (this.isDeviceRegister == 'gateway') {
        this.getGatewayWidgetDetail();
      }
      this.setFormControl();
      setTimeout(() => {
        this.editImages = data.image
        if (this.imageElement) {
          this.imageElement.imageUrl = data.image;
        }
        this.form?.form?.controls[ControlName.imageUpload]?.setValue(data?.type == 1 ? true : false);
        this.form?.form?.controls[ControlName.imageUpload]?.disable();
        if (this.form) {
          this.form?.form.patchValue({
            type: data?.type,
            title: data?.title || data?.name || data?.label,
            dxPositionX: data?.dx_position,
            dxPositionY: data?.dy_position,
            facility: data?.facility?.id,
            point_of_interest: data?.point_of_interest,
            building: data?.building?.id,
            latitude: data?.latitude,
            longitude: data?.longitude,
            map_type: data?.map_type
          });
        }
      }, 0);
      if (this.deviceConfig?.data?.length) {
        this.deviceConfig.data.map((config: any) => {
          config.linked = (config?.item?.floorId?.id || config?.item?.floor?.id) == this.selectedFloor[0].id ? true : false,
            config.draggable = !config.device?.floorId ? true : false
        });
      }
    }
  }

  onDeleteWidgetDetail(id: string) {
    this.showFormSection = false;
    let deleteIndex: number;
    let deleteObservable: Observable<any>;
    switch (this.selectedTab) {
      case SelectedTab.facilities:
        deleteObservable = this.facilityService.deleteFacility(id);
        break;
      case SelectedTab.buildings:
        deleteObservable = this.facilityService.deleteBuilding(id);
        break;
      case SelectedTab.floors:
        deleteObservable = this.facilityService.deleteFloor(id);
        break;
      default:
        return;
    }
    if (deleteObservable) {
      deleteObservable.subscribe(() => {
        deleteIndex = this.tableConfig.data.findIndex((item: any) => item.item.id == id);
        if (deleteIndex > -1) {
          this.tableConfig.data.splice(deleteIndex, 1);
        }
      });
    }
  }

  onRegistration(registerKey: string) {
    this.deviceConfig = null;
    this.isDeviceRegister = registerKey
    if (this.isDeviceRegister == 'device') {
      this.getAllDevices();
    } else {
      this.isDeviceRegister = registerKey
      this.getGatewayWidgetDetail();
    }
    this.isRegistrationView = true;
  }

  getAllDevices() {
    this.campusService.getAllDevices().subscribe((res: any) => {
      const filteredData = res.data.filter((device: any) => device.type === 'Fixed');
      const headers = [
        { header: 'Devices', field: 'item' },
        { header: 'Assign', field: 'assign' }
      ];
      this.deviceConfig = this.manipulateDeviceListing(filteredData, headers);
    })
  }

  // To modify the devices and gateways to show on the table view......
  manipulateDeviceListing(list: any = [], headers: any) {
    const config: { headers: Array<{ header: string, field: string }>, data: Array<{ item: any, assign: any }> } = {
      headers: [],
      data: []
    };
    config.headers = headers;
    list.forEach((obj: any) => {
      if (obj?.gateway_id) {
        obj.name = obj.gateway_id
      }
      const deviceConfig: { item: any, assign: any, linked: boolean, draggable: boolean } = {
        item: obj,
        assign: (obj?.floorId || obj.floor) ? (obj?.buildingId?.name || obj?.building?.name) + ' > ' + (obj?.floorId?.label || obj?.floor?.label) : '',
        linked: (obj?.floorId?.id || obj?.floor?.id) == this.selectedFloor[0].id ? true : false,
        draggable: !(obj?.floorId?.id || obj?.floor?.id) ? true : false
      };
      config.data.push(deviceConfig);
    });
    return config;
  }

  // Api call for the gateways
  getGatewayWidgetDetail() {
    this.gatewayService.getGateways('').subscribe((res: allFacilityDetails) => {
      const headers = [
        { header: 'Gateways', field: 'item' },
        { header: 'Assign', field: 'assign' }
      ];
      this.deviceConfig = this.manipulateDeviceListing(res.data, headers);
    });
  }

  //create table config
  createTableConfig(gateway: any) {
    const headers = [
      { header: 'Images', field: 'image' },
      { header: 'Facilities', field: 'facility' },
      { header: 'Buildings', field: 'building' },
      { header: 'Floors', field: 'floor' },
      { header: 'Actions', field: 'action' },
    ];
    if (this.selectedTab == 'Facilities') {
      headers.splice(2, 2);
    } else if (this.selectedTab == 'Buildings') {
      headers.splice(3, 1);
    }
    const data: any = []
    gateway.forEach((e: any) => {
      if (this.selectedTab == 'Facilities') {
        data.push(this.createItem(e));
      }
      if (this.selectedTab == 'Buildings') {
        data.push(this.createItem(e));
      }
      if (this.selectedTab == 'Floors/Area') {
        data.push(this.createItem(e));
      }
    });
    this.tableConfig = { header: headers, data: data };
  }

  // create data for table config
  createItem(data: any) {
    const config: any = {
      item: data,
      image: data.image,
      action: {
        edit: true,
        delete: true,
      }
    }
    if (this.selectedTab == 'Facilities') {
      config['facility'] = data.title != null ? data.title : '';
    }
    if (this.selectedTab == 'Buildings') {
      config['facility'] = data.facility != null ? data.facility.title : '';
      config['building'] = data.name != null ? data.name : '';
    }
    if (this.selectedTab == 'Floors/Area') {
      config.action['geofence'] = false;
      config['facility'] = data.facility != null ? data.facility.title : '';
      config['building'] = data.building != null ? data.building.name : '';
      config['floor'] = data.label != null ? data.label : '';
    }
    return config;
  }

  editImage() {
    const openImageEditor = (imageData: any) => {
      const ref = this.dialogService.open(ImageEditorComponent, {
        data: { editImage: imageData },
        header: 'Edit Image',
        width: '50vw',
      });

      ref.onClose.subscribe((result: any) => {
        this.imageElement.imageUrl = result.image;
        this.imageFile = this.utility.base64ToBlob(result.image, 'image/png');
      });
    };

    if (this.editImages) {
      this.facilityService.getImage({ url: this.editImages }).subscribe((res: any) => {
        openImageEditor(res.data);
      });
    } else {
      openImageEditor(this.imageFile);
    }
  }

  close() {
    this.isDeviceRegister = '';
    this.showFormSection = false;
    this.editRowData = null;
    this.selectedEditRow.selectedRowIndex = -1;
    this.isRegistrationView = false;
    this.editImages = undefined;
  }

  //geoFence 
  openGeofence(data:any) {
    this.showGeofence = data.showGeofence;
    this.floorData = data.item;
  }

  backToFloor (event:any) {
    this.showGeofence = false;  
  }
}

