import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import * as L from 'leaflet';
import 'leaflet-draw';
import { ConfirmationService } from 'primeng/api';
import { WidgetsComponent } from 'src/app/shared/ui-components/widgets/widgets.component';
import { GeofenceController } from './geofence-controller';
import { GeofenceCategory } from './geofence.interface';
import { GeofenceService } from './services/geofence.service';
import { Category } from './models/category';
import { HeadersObj } from 'src/app/shared/constants/constants';

// Override the `readableArea` method in Leaflet Draw
(L as any).GeometryUtil.readableArea = function() {
  return ''; // Returning an empty string disables the area calculation display
};
@Component({
  selector: 'app-geofencing',
  templateUrl: './geofencing.component.html',
  styleUrls: ['./geofencing.component.scss']
})
export class GeofencingComponent extends GeofenceController implements OnInit {
  // @ViewChild(GeofenceListingComponent) geoListComp!: GeofenceListingComponent;
  @ViewChild('fileInput') fileInput!: ElementRef;
  @ViewChild(WidgetsComponent) selectedEditRow!: WidgetsComponent;
  @ViewChild('form') form!: any;

  @Input() floorData: any;
  @Output() backToFloor = new EventEmitter<any>()
  tableConfig: any;
  btnText = "Save";
  // categoryConfig = categoryFieldConfig;
  // drawnItems!: L.FeatureGroup;

  // currentImageLayer!: L.ImageOverlay;
  // baseMapLayer!: L.TileLayer;
  // geofenceName: string = '';
  // cloneName = '';
  // geofenceColor: string = '#3388ff'; // Default color
  // private originalGeofenceState: any = {};

  // categories: any = [
  //   { id: 0, name: 'Ungrouped', description: 'This is the default category for geofences that are not assigned to any specific group.', geofences: [] },
  //   { id: 1, name: 'Storage', description: '', geofences: [] },
  //   { id: 2, name: 'Office', description: '', geofences: [] },
  //   { id: 3, name: 'Restroom', description: '', geofences: [] },
  // ];
  // selectedCategory: any = this.categories[0];

  // displayCategoryDialog: boolean = false;
  // category: GeofenceCategory = { id: 0, name: '', description: '', geofence: [] };
  // currentGeofence: any;
  // tableConfig: any;
  // btnText = "Save";
  // lastHighlightedGeofence: any = [];

  // // Variable to track the currently editing geofence
  // currentlyEditingGeofence: any = null;
  

  constructor(private confirmationService: ConfirmationService, private geofenceService: GeofenceService) {
    super()
  }

  ngOnInit(): void {
    this.initializeMap();
    this.onEditDetail(this.floorData);
    this.getallCategories();
  }

  //method for getting all the categories
  getallCategories() {
    this.geofenceService.getAllCategories().subscribe({
      next: ((res: any) => {
        this.categories = [ ...res.data ];
        this.selectedCategory = this.categories.find((item: Category) => item.name == 'Ungrouped');
        this.createTableConfig(this.categories);
      }),
      error: ((error: any) => {
        // this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Action Failed' })
      }) 
    })
  }
  
  saveGeofences() {
    // localStorage.setItem('geofences', JSON.stringify(this.geofences));
  }

  onEditDetail(floor: any) {
    const imageUrl = floor.image;

    if (this.currentImageLayer) {
      this.map.removeLayer(this.currentImageLayer);
    }

    if (this.baseMapLayer) {
      this.map.removeLayer(this.baseMapLayer);
    }

    const imageBounds: any = [
      [0, 0], // Southwest corner
      [floor.dy_position, floor.dx_position] // Northeast corner
    ];

    this.currentImageLayer = L.imageOverlay(imageUrl, imageBounds).addTo(this.map);
    this.map.fitBounds(this.currentImageLayer.getBounds());
    // this.map.setZoom(5);
  }

  saveGeofence() {
  }

  goBack() {
    this.backToFloor.emit();
    // Implement navigation or functionality to go back
  }

  saveCategory(formConfig: any) {
    let payload: GeofenceCategory = {
      ...formConfig?.value
    }
    if (formConfig.invalid) {
      formConfig.markAllAsTouched();
    } else {
      if (this.btnText == 'Save') {
        this.geofenceService.addCategory(payload, HeadersObj).subscribe({
          next: ((res: any) => {
            this.categories.push(res.data[0]);
            this.tableConfig.data.push(this.createTableData(res.data[0]));
            this.form.form.reset({
              name: '',
              description: ''
            });
            // this.createTableConfig(this.categories);
          })
        })
        // this.categories.push(payload);
      } else if (this.btnText = 'Update') {
        payload['id'] = this.category.id;
        this.geofenceService.editCategory(payload).subscribe({
          next: ((res: any) => {
            let index = this.categories.findIndex((item: Category) => item.id == this.category.id);
            this.categories.splice(index, 1 , res.data[0]);
            this.tableConfig.data.splice(index, 1 , this.createTableData(res.data[0]));
            this.onEditCategory(this.category);
            // this.createTableConfig(this.categories);
          })
        })
        // let index = this.categories.findIndex((item: GeofenceCategory) => item.id == this.category.id);
        // this.categories[index].name = this.form.form.controls['name'].value;
        // this.categories[index].description = this.form.form.controls['description'].value;
        // this.onEditCategory(this.category);
      }
      // this.createTableConfig(this.categories);
    }
  }

  createNewCategory() {
    this.displayCategoryDialog = true;
  }

  deleteCategory(id: string) {
  }

  deleteSelectedGeofence(data: any) {
    this.confirmationService.confirm({
      header: 'Alert',
      icon: 'pi pi-exclamation-triangle',
      message: 'Are you sure that you want to delete the selected geofence',
      accept: () => {
        const category = this.geofences.find((g: any) => g.id === data.categoryId);
        if (category) {
          // Check if the geofence is a child of another geofence
          if (data.geofence.parentId) {
            const parentGeofence = category.geofences.find((g: any) => g.id === data.geofence.parentId);

            if (parentGeofence) {
              // Remove the geofence from the parent's children
              parentGeofence.children = parentGeofence.children.filter((child: any) => child.id !== data.geofence.id);
            }
          } else {
            // Remove the geofence directly from the category's geofences
            data.geofence?.children.forEach((geofence:any) => {
              this.drawnItems.removeLayer(geofence.layer);
            })
            category.geofences = category.geofences.filter((g: any) => g.id !== data.geofence.id);  
          }
          this.drawnItems.removeLayer(data.geofence.layer);
        }
      },
      reject: () => {
        return;
      }
    });
  }

  createTableConfig(category: any) {
    const headers = [
      { header: 'Name', field: 'name' },
      { header: 'Description', field: 'description' },
      { header: 'Actions', field: 'action' }
    ];
    const data: any = [];
    category.forEach((element: any) => {
      let config = this.createTableData(element);
      data.push(config);
    });
    this.tableConfig = { header: headers, data: data };
  }

  createTableData(element: Category) {
    const actionCheck = ((name: any) => name !== "Ungrouped") 
    let config = {
      item: element,
      name: element.name,
      description: element.description,
      action: { edit: actionCheck(element.name), delete: actionCheck(element.name) }
    }
    return config;
  }

  onDeleteCategory(id: number) {
    this.confirmationService.confirm({
      header: 'Alert',
      icon: 'pi pi-exclamation-triangle',
      message: 'Are you sure that you want to delete the selected category',
      accept: () => {
        this.geofenceService.deleteCategory(id).subscribe({
          next: ((res: any)=>{
            let index = this.categories.findIndex((item: Category) => item.id == id);
            this.categories.splice(index, 1);
            this.tableConfig.data.splice(index,1 )
            // this.createTableConfig(this.categories);
          })
        })
        // let index = this.categories.findIndex((item: GeofenceCategory) => item.id == id);
        // this.categories.splice(index, 1);
        // this.createTableConfig(this.categories);
      },
      reject: () => {
        return;
      }
    });
  }

  onEditCategory(data: any) {
    if (this.selectedEditRow.selectedRowIndex === data.id) {
      this.selectedEditRow.selectedRowIndex = -1;
      this.form.form.reset({
        name: '',
        description: ''
      });
      this.btnText = "Save"
    } else {
      this.selectedEditRow.selectedRowIndex = data.id;
      this.btnText = "Update"
      this.category = { ...data }
      this.form.form.controls['name'].setValue(data.name);
      this.form.form.controls['description'].setValue(data.description);
    }
  }

  onHide(event: any) {
    this.selectedEditRow.selectedRowIndex = -1;
    if(this.selectedCategory.name != 'Ungrouped') {
     let currentCategory = this.categories.find((cat:Category) => cat.id == this.selectedCategory.id);
     if(!currentCategory) this.selectedCategory = this.categories[0];
    }
    this.form.form.reset({
      name: '',
      description: ''
    });
    this.btnText = "Save"
  }
}
