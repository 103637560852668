import { HttpClient, HttpHeaders } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { ApiEndpoints } from 'src/app/core/config/api-endpoints.config';
import { environment } from 'src/environments/environment';
import { Category, CategoryPayload } from '../models/category';
import { EventService } from 'src/app/shared/services/event.service';

@Injectable({
  providedIn: 'root'
})
export class GeofenceService {

  url: any = environment.loginUrl;
  endPoints = ApiEndpoints.settings.Geofence;
  http = inject(HttpClient);
  headers = new HttpHeaders({
    'spinner': 'false'
  });

  constructor( private serviceEvent: EventService) { }

  getAllCategories () {
    const url = `${this.url}${this.endPoints.getCategories}`;
    return this.http.get<any>(url, {headers: this.headers});
  }

  addCategory(payload: CategoryPayload, HeadersObj: { spinner: string; }) {
    const url = `${this.url}${this.endPoints.addCategory}`;
    return this.http.post<Category>(url, payload, {headers: this.headers});
  }

  editCategory(payload: Category) {
    const url = `${this.url}${this.endPoints.editCategory}`;
    return this.http.put<Category>(url, payload, {headers: this.headers});
  }

  deleteCategory(id: number) {
    const url = `${this.url}${this.endPoints.deleteCategory}${id}`;
    return this.http.delete<any>(url, {headers: this.headers});
  }
}
