import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, switchMap, tap, finalize } from 'rxjs/operators';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/authentication/components/login/services/auth.service';
import { MessageService } from 'primeng/api';
import { EventService } from 'src/app/shared/services/event.service';
import { CookieService } from 'ngx-cookie-service';

@Injectable()
export class HttpHandlerInterceptor implements HttpInterceptor {
  spinner: boolean = false;

  constructor(
    private authService: AuthService,
    private serviceEvent: EventService,
    private messageService: MessageService,
    private cookieService: CookieService,
    private router: Router
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const spinner = req.headers.get('spinner') || 'true';
    req.headers.delete('tokenConfig');
    let authToken: string | null = sessionStorage.getItem('token') || this.cookieService.get('ACCESS_TOKEN');

    const isLoginOrRefreshRequest = (url: any): boolean => {
      if (!url) return false;
      const urlParts = url.split('/');
      const endpoint = urlParts[urlParts.length - 1];
      return endpoint === 'login' || endpoint === 'refresh';
    };
    
    const addAuthToken = (request: HttpRequest<any>, token: string): HttpRequest<any> => {
      return request.clone({
        setHeaders: {
          'Authorization': `Bearer ${authToken}`
        }
      });
    };
    let authReq = req;
    if (!isLoginOrRefreshRequest(req.url)) {
      authReq = addAuthToken(req, authToken);
    }

    if (JSON.parse(spinner) && !this.spinner) {
      this.spinner = true;
      this.serviceEvent.loaderService.next(true);
    }

    const handle401Error = (request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> => {
      return this.authService.refreshToken().pipe(
        switchMap((response: any) => {
          const newAuthToken = response.accessToken;
          sessionStorage.setItem('token', newAuthToken);
          this.cookieService.set('ACCESS_TOKEN', newAuthToken);
          authToken = newAuthToken; // Update the authToken variable
          return next.handle(addAuthToken(request, newAuthToken));
        }),
        catchError((err: any) => {
          sessionStorage.removeItem('token');
          sessionStorage.removeItem('refreshToken');
          this.router.navigate(['/login']);
          return throwError(() => err);
        })
      );
    };

    return next.handle(authReq).pipe(
      tap({
        next: (res) => {
          if (res instanceof HttpResponse) {
            // const message: any = res
            // this.messageService.add({ severity: 'success', summary: 'Success', detail:message.body.message })
          }
        },
        error: (err: any) => {
          if (err instanceof HttpErrorResponse) {
            if (JSON.parse(spinner)) {
              this.spinner = false;
              this.serviceEvent.loaderService.next(false);
            }
            if (err.status === 401 && !isLoginOrRefreshRequest(err.url)) {
              console.error('Unauthorized request:', err);
            } else {
              this.messageService.add({ severity: 'error', summary: 'Error', detail: err.error.message || err.error.error });
              console.error('HTTP error:', err);
            }
          } else {
            console.error('An error occurred:', err);
          }
        },
      }),
      catchError((err: any) => {
        if (err instanceof HttpErrorResponse && err.status === 401 && !isLoginOrRefreshRequest(err.url)) {
          sessionStorage.removeItem('token');
          return handle401Error(req, next);
        }
        return throwError(() => err);
      }),
      finalize(() => {
        if (JSON.parse(spinner)) {
          this.spinner = false;
          this.serviceEvent.loaderService.next(false);
        }
      })
    );
  }
}
