import * as L from 'leaflet';
import 'leaflet-draw';
import { Geofence, GeofenceCategory } from "./geofence.interface";
import { categoryFieldConfig } from "./services/add-categorey-type-constant";
import { GeofenceListingComponent } from 'src/app/shared/ui-components/geofence-listing/geofence-listing.component';
import { Component, ViewChild } from '@angular/core';
import { Category } from './models/category';

@Component({
    selector: '',
    template: ``
  })

export class GeofenceController {
  @ViewChild(GeofenceListingComponent) geoListComp!: GeofenceListingComponent;

    geofences: any[] = []; // Array to store geofences in hierarchical format
    categoryConfig = categoryFieldConfig;
    map!: L.Map;
    drawnItems!: L.FeatureGroup;
  
    currentImageLayer!: L.ImageOverlay;
    baseMapLayer!: L.TileLayer;
    geofenceName= '';
    cloneName = '';
    geofenceColor = '#3388ff'; // Default color
    cloneColor= '';
    originalGeofenceState: any = {};
  
    categories!: Category [];
    selectedCategory!: any;
  
    displayCategoryDialog: boolean = false;
    category!: Category;
    currentGeofence: any;
    lastHighlightedGeofence: any = [];
  
    // Variable to track the currently editing geofence
    currentlyEditingGeofence: any = null;

    constructor(){
    }

    // Helper to create a geofence with layer details
    createGeofence(layer: any, boundsOrCenter: any, radius?: number) {
    let geoName = '';
    const selectedCategory = this.geofences.find((category: any) => category.id === this.selectedCategory.id);
    let largestNumber = 0;
  
    if (selectedCategory?.geofences) {
      const geofencesWithHyphen = selectedCategory.geofences.flatMap((geo: any) => {
        const hasHyphenInName = geo.name.includes('-');
        const childGeofences = geo.children?.filter((child: any) => child.name.includes('-')) || [];
        return hasHyphenInName || childGeofences.length ? [geo, ...childGeofences] : [];
      });
  
      const numbers = new Set<number>();
      geofencesWithHyphen.forEach((geo: any) => {
        const numPart = parseInt(geo.name.split('-').pop(), 10);
        if (!isNaN(numPart)) numbers.add(numPart);
      });
  
      largestNumber = Math.max(0, ...numbers); // Get the largest number
    }
  
    const newGeofence: any = {
      id: Date.now(),
      name: geoName,
      color: this.cloneColor ? this.cloneColor : this.geofenceColor,
      draggable: true,
      layer: layer,
      boundaries: radius 
        ? { center: boundsOrCenter, radius: radius } 
        : {
            xMin: boundsOrCenter.getSouthWest().lng,
            yMin: boundsOrCenter.getSouthWest().lat,
            xMax: boundsOrCenter.getNorthEast().lng,
            yMax: boundsOrCenter.getNorthEast().lat
          },
      parentId: null,
      children: [],
      expanded: false
    };
  
    const parentGeofence = this.getParentGeofence(newGeofence);
    if (parentGeofence) {
      const parentNumber = parseInt(parentGeofence.name.split('-').pop(), 10) || 0;
      const existingChildren = parentGeofence.children || [];
      const childNumbers = existingChildren.map((child: any) => parseInt(child.name.split('-').pop(), 10) || 0);
      const nextChildNumber = Math.max(...childNumbers, 0) + 1;
  
      geoName = this.cloneName 
        ? this.cloneName 
        : this.geofenceName
        || `${this.selectedCategory.name} - ${parentNumber}-${nextChildNumber}`;
    } else {
      geoName = this.cloneName 
        ? this.cloneName 
        : this.geofenceName
        || `${this.selectedCategory.name} - ${largestNumber + 1}`;
    }
  
    newGeofence.name = geoName.trim();
    return newGeofence;
    }

    // Handle adding geofences
    toAddGeofence() {
        this.map.on(L.Draw.Event.CREATED, (event: any) => {
        const layer = event.layer;
        let newGeofence: any;
        
        if (event.layerType === 'circle') {
            const radius = layer.getRadius();
            const latlng = layer.getLatLng();
            newGeofence = this.createGeofence(layer, latlng, radius);

        } else if (event.layerType === 'rectangle' || event.layerType === 'polygon' || event.layerType === 'polyline') {
            const bounds = layer.getBounds();
            newGeofence = this.createGeofence(layer, bounds);
        }

        this.handleGeofenceCreation(newGeofence);
        this.attachContextMenu(newGeofence.layer, event.layerType); // Attach context menu to the layer
        });
    }

    getParentGeofence(newGeofence: any, checkIfCopy: boolean = false) {
        let parentGeofence: any = null;
        for (const category of this.geofences) {
            for (const geofence of category.geofences) {
                if (this.isGeofenceInside(geofence, newGeofence, category.id) && !checkIfCopy) {
                    parentGeofence = geofence;
                    break;
                }
            }
        }
        return parentGeofence;
    }

    // Function to check if newGeofence is inside parentGeofence (bounding box check)
    isGeofenceInside(parentGeofence: Geofence, newGeofence: Geofence, categoryId: any): boolean {
        const parentBounds = parentGeofence.boundaries;
        const newBounds = newGeofence.boundaries;

        return (
        categoryId == this.selectedCategory.id &&
        newBounds.xMin >= parentBounds.xMin &&
        newBounds.yMin >= parentBounds.yMin &&
        newBounds.xMax <= parentBounds.xMax &&
        newBounds.yMax <= parentBounds.yMax
        );
    }

    // Handle geofence creation logic (whether it's inside another or not)
    handleGeofenceCreation(newGeofence: any, checkIfCopy: boolean = false) {
        let parentGeofence: any = this.getParentGeofence(newGeofence, checkIfCopy);   
        let existingCategory = this.geofences.find(cat => cat.id === this.selectedCategory.id);

        if (parentGeofence) {
        parentGeofence.class = 'sub-child';
        parentGeofence.children.push(newGeofence);
        newGeofence.parentId = parentGeofence.id;
        existingCategory.expanded = true;
        parentGeofence.expanded = true;
        } else {
        if (existingCategory) {
            existingCategory.geofences.push(newGeofence);
            existingCategory.expanded = true;
        } else {
            this.geofences.push({
            id: this.selectedCategory.id,
            name: this.selectedCategory.name,
            description: this.selectedCategory.description,
            geofences: [newGeofence],
            expanded: true,
            });
        }
        }
        newGeofence.layer.setStyle({ color: newGeofence.color });
        newGeofence.layer.bindTooltip(newGeofence.name, {
        permanent: true,
        position: 'center'
        }).openTooltip();
        this.drawnItems.addLayer(newGeofence.layer);
        this.geofenceName = '';
    }

    // Function to copy geofence geometries with an offset
    copyGeofence(originalLayer: any) {
        originalLayer.openTooltip();
        const offsetDistance = 0.0005; // Small offset to avoid exact overlap
        const baseName = originalLayer._tooltip._content + " - Copy";

        // Use the helper function to get a unique geofence name
        this.cloneName = this.getUniqueGeofenceName(baseName, this.geofences);
        this.cloneColor =  originalLayer.options.color;
        let copiedLayer: any;
        let type: string = this.getLayerType(originalLayer); // Get the layer type
        
        let newLatLngs: any;
    
        // Create the copied layer based on type
        switch (type) {
        case 'circle':
            const newCenter = L.latLng(
            originalLayer.getLatLng().lat + offsetDistance,
            originalLayer.getLatLng().lng + offsetDistance
            );
            copiedLayer = L.circle(newCenter, {
            radius: originalLayer.getRadius(),
            opacity: originalLayer.options.opacity,
            }).addTo(this.map);
            break;
        case 'rectangle':
            // Copy the layer with an offset
            newLatLngs = this.offsetLatLngs(originalLayer.getLatLngs(), offsetDistance);
            copiedLayer = L.rectangle(newLatLngs, {
              opacity: originalLayer.options.opacity,
            }).addTo(this.map);
            break;
        case 'polygon':
            // Copy the layer with an offset
            newLatLngs = this.offsetLatLngs(originalLayer.getLatLngs(), offsetDistance);
            copiedLayer = L.polygon(newLatLngs, {

            }).addTo(this.map);
            break;
        case 'polyline':
            newLatLngs = this.offsetLatLngs(originalLayer.getLatLngs(), offsetDistance);
            copiedLayer = L.polyline(newLatLngs, {
            opacity: originalLayer.options.opacity,
            }).addTo(this.map);
            break;
        }

        // Bind the copied layer to tooltip and handle geofence creation
        if (copiedLayer) {
        const copiedGeofence = this.createGeofence(copiedLayer, copiedLayer.getBounds() || copiedLayer.getLatLng());
        this.handleGeofenceCreation(copiedGeofence, true);
        this.cloneName = '';
        this.cloneColor = '';  
        // Attach context menu and enable editing mode
        this.attachContextMenu(copiedLayer, type);
        this.enableGeofenceEditing(copiedLayer);
        }
    }  

    // Helper function to determine the layer type
    getLayerType(layer: any): string {
        if (layer instanceof L.Circle) return 'circle';
        if (layer instanceof L.Rectangle) return 'rectangle';
        if (layer instanceof L.Polygon) return 'polygon';
        if (layer instanceof L.Polyline) return 'polyline';
        return '';
    }

    // Helper function to offset lat/lngs for geometries (handles both polyline and polygon cases)
    offsetLatLngs(latlngs: any, offsetDistance: number): any[] {
        if (!Array.isArray(latlngs)) {
            return []; // Ensure latlngs is an array
        }

        // Check if the latlngs is an array of arrays (for polygons) or a flat array (for polylines)
        if (Array.isArray(latlngs[0])) {
            // Handle nested arrays for polygons (array of rings)
            return latlngs.map((latlngArray: any) =>
            latlngArray.map((latlng: any) => L.latLng(latlng.lat + offsetDistance, latlng.lng + offsetDistance))
            );
        } else {
            // Handle flat array for polylines
            return latlngs.map((latlng: any) => L.latLng(latlng.lat + offsetDistance, latlng.lng + offsetDistance));
        }
    }

    // Function to check for existing geofence names and generate a unique name
    getUniqueGeofenceName(baseName: string, geofences: any[]): string {
        let count = 1;
        let uniqueName = baseName;

        const checkGeofenceName = (geofence: any) => {
        if (geofence.name.startsWith(uniqueName)) {
            uniqueName = baseName + ` (${++count})`;
        }

        // Check in nested children if present
        if (geofence.children && geofence.children.length) {
            geofence.children.forEach((childGeofence: any) => {
            checkGeofenceName(childGeofence); // Recursively check child geofences
            });
        }
        };

        // Iterate through the geofences to find duplicates and adjust the name
        geofences.forEach((geofenceGroup: any) => {
        geofenceGroup.geofences.forEach((geofence: any) => {
            checkGeofenceName(geofence); // Check for name conflicts
        });
        });

        return uniqueName; // Return the final unique name
    }

    loadGeofences() {
        const savedGeofences = JSON.parse(localStorage.getItem('geofences') || '[]');
        savedGeofences.forEach((geofence: any) => {
          let layer: L.Layer;
          if (geofence.layer.type === 'circle') {
            layer = L.circle(geofence.layer.latlngs, geofence.layer.options);
          } else if (geofence.layer.type === 'polygon') {
            layer = L.polygon(geofence.layer.latlngs, geofence.layer.options);
          } else {
            return; // Skip unsupported types
          }
          this.drawnItems.addLayer(layer);
          this.geofences.push({
            ...geofence,
            layer: layer,
            children: [] // Initialize children array
          });
        });
    }

    highlightGeofence(geofenceGroup: any) {
        // Reset the last highlighted geofences to their original colors
        this.lastHighlightedGeofence.forEach((geofence: any) => {
          (geofence.layer as any).setStyle({ color: geofence.color });
        });
    
        let lastGeofences: any[] = [];
    
        // Check if geofenceGroup is an object (single geofence) or an array of geofences
        if (typeof geofenceGroup === 'object' && !Array.isArray(geofenceGroup.geofences)) {
          // Highlight single geofence
          if (!this.lastHighlightedGeofence.includes(geofenceGroup)) {
            (geofenceGroup.layer as any).setStyle({ color: '#ff7800' }); // Highlight color
            this.map.fitBounds(geofenceGroup.layer.getBounds()); // Fit map to bounds of the geofence
            lastGeofences = [geofenceGroup]; // Store with set color
          }
          else {
            this.map.fitBounds(this.currentImageLayer.getBounds());
          }
        } else if (Array.isArray(geofenceGroup.geofences)) {
          if (this.lastHighlightedGeofence.length == 1) {
            this.lastHighlightedGeofence = []
          }
          // Highlight multiple geofences
          geofenceGroup.geofences.forEach((geofence: any) => {
            this.drawnItems.eachLayer((layer: L.Layer) => {
              if (geofence.layer === layer && !this.lastHighlightedGeofence.includes(geofence)) {
                lastGeofences.push(geofence); // Store with set color
                (geofence.layer as any).setStyle({ color: '#ff7800' }); // Highlight color
              }
            });
          });
        }
    
        // Update the last highlighted geofences
        this.lastHighlightedGeofence = lastGeofences;
    }

    initializeMap() {
        this.map = L.map('map', {
          zoom: 2,
        });
    
        this.baseMapLayer = L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png').addTo(this.map);
    
        if (this.baseMapLayer) {
          this.map.addLayer(this.baseMapLayer);
        }
        this.drawnItems = new L.FeatureGroup();
        this.map.addLayer(this.drawnItems);
    
        const drawControl = new L.Control.Draw({
          edit: {
            edit: false,
            featureGroup: this.drawnItems,
            remove: false
          },
          draw: {
            circlemarker: false,
            marker: false,
            polygon: {
              showArea: true,
              shapeOptions: {
                color: 'purple'
              }
            },
            rectangle: {
              shapeOptions: {
                color: 'blue'
              }
    
            },
            circle: {
              shapeOptions: {
                color: 'green'
              }
            }
          }
        });
        this.map.addControl(drawControl);
        this.toAddGeofence();
    }
    
    // Attach context menu to a layer
    attachContextMenu(layer: any, type: string) {
    layer.on('contextmenu', (e: any) => {
        this.showContextMenu(e, layer, type);
    });
    }
    
    // Show custom context menu
    showContextMenu(event: any, geofenceLayer: any, type: string) {
    // Create the menu container if it doesn't exist
    let menu = document.getElementById('custom-context-menu');
    if (!menu) {
        menu = document.createElement('div');
        menu.id = 'custom-context-menu';
        menu.className = 'geofence-context-menu'
        document.body.appendChild(menu);
    }

    // Position the context menu at the mouse position
    menu.style.left = `${event.originalEvent.pageX}px`;
    menu.style.top = `${event.originalEvent.pageY}px`;

    // Clear the previous menu items
    menu.innerHTML = '';

    // Add "Edit" option to the context menu
    const editOption = document.createElement('div');
    editOption.innerHTML = '<i class="pi pi-pen-to-square"></i> Edit Geofence';
    editOption.className = 'geofence-context-menu-items';
    editOption.onclick = () => {
        this.enableGeofenceEditing(geofenceLayer); // Enable editing mode
        this.hideContextMenu(); // Hide the context menu after selection
    };
    menu.appendChild(editOption);

    // Add "Copy" option conditionally based on the geofence type
    // if (geofenceLayer && (type === 'circle' || type === 'rectangle')) {
        const copyOption = document.createElement('div');
        copyOption.innerHTML = '<i class="pi pi-clone"></i> Copy Geofence';
        copyOption.className = 'geofence-context-menu-items';
        copyOption.onclick = () => {
        this.copyGeofence(geofenceLayer); // Copy the selected geofence
        this.hideContextMenu(); // Hide the context menu after selection
        };
        menu.appendChild(copyOption);

    // Option to close the menu
    const closeOption = document.createElement('div');
    closeOption.innerHTML = '<i class="pi pi-times"></i> Close Menu';
    closeOption.className = 'geofence-context-menu-items';
    closeOption.onclick = () => {
        this.hideContextMenu();
    };
    menu.appendChild(closeOption);
    }
    
    // Hide the context menu
    hideContextMenu() {
        const menu = document.getElementById('custom-context-menu');
        if (menu) {
          menu.style.left = '-9999px'; // Move it off-screen
        }
    }

    onEditGeofence(geofence: any) {
        if(this.geoListComp.currentGeofence == null || this.geoListComp.currentGeofence.id !== geofence.geofence.id) {
          this.geoListComp.currentGeofence = geofence.geofence;
          this.geofenceName = geofence.geofence.name;
          this.selectedCategory = this.categories.find((cat:Category) => cat.id == geofence.category.id);
        } else if(this.geoListComp.currentGeofence.id == geofence.geofence.id) {
          geofence.geofence.name = this.geofenceName.trim() ? this.geofenceName : 'Default' ;
          this.geoListComp.currentGeofence.layer.setStyle({ color: this.geofenceColor }); // Apply selected color
          this.geoListComp.currentGeofence.layer.bindTooltip(this.geofenceName, {
            permanent: true,
          }).openPopup();
    
          if (geofence.category.id !== this.selectedCategory.id) {
            // Find or create the selected category
            let newCategory = this.geofences.find((cat: any) => cat.id === this.selectedCategory.id);
            if (!newCategory) {
              this.selectedCategory.geofences = [geofence.geofence]
              this.geofences.push(this.selectedCategory);
              // Find the existing category by geofence's current categoryId
              const currentCategory = this.geofences.find((cat: any) => cat.id === geofence.category.id);
              // Remove the geofence from the current category if it exists
              if (currentCategory) {
                if(geofence.geofence.parentId) {
                  currentCategory.geofences.filter((parent: any) => parent.id !== geofence.geofence.parentId)
                }
                currentCategory.geofences = currentCategory.geofences.filter((g: any) =>{
                  g.children =  g?.children.filter((g: any) => 
                    g.id !== geofence.geofence.id);
                  if(g.id !== geofence.geofence.id){
                    return g
                  }  
                })
                // If the category is now empty, remove it from the list
                if (currentCategory.geofences.length === 0) {
                  this.geofences = this.geofences.filter((cat: any) => cat.id !== currentCategory.id);
                }
              }
            }
            else {
              // Find the existing category by geofence's current categoryId
              const currentCategory = this.geofences.find((cat: any) => cat.id === geofence.category.id);
              // Remove the geofence from the current category if it exists
              if (currentCategory) {
                currentCategory.geofences = currentCategory.geofences.filter((g: any) =>{
                  g.children =  g?.children.filter((g: any) => 
                    g.id !== geofence.geofence.id);
                  if(g.id !== geofence.geofence.id){
                    return g
                  }  
                })
                // If the category is now empty, remove it from the list
                if (currentCategory.geofences.length === 0) {
                  this.geofences = this.geofences.filter((cat: any) => cat.id !== currentCategory.id);
                }
                // Add the geofence to the selected category
                newCategory.geofences.push(geofence.geofence);
              }
            }
          }
          // }
          // Clear input fields after saving
          this.geoListComp.currentGeofence = null;
          this.geoListComp.currentGeofence = null;
          this.geofenceName = '';
          this.selectedCategory = this.categories[0];
          this.geofenceColor = '#3388ff';
        }
    }

    // Enable editing mode for the selected geofence
    enableGeofenceEditing(geofenceLayer: any) {
    let originalRadius: any;
    let newRadius: any;
    let newLatLng: any;
    geofenceLayer.closeTooltip();
  
    // If another geofence is currently in edit mode, disable its editing
    if (this.currentlyEditingGeofence && this.currentlyEditingGeofence !== geofenceLayer) {
      this.currentlyEditingGeofence.editing.disable(); // Disable edit mode for the previous geofence
      this.map.removeControl(this.currentlyEditingGeofence.control); // Remove controls
    }
  
    // Custom logic for radius resizing
    L.Edit.Circle.include({
      _resize: function (e: any) {
        if (!originalRadius) {
          originalRadius = this._shape.getRadius();
        }
        newLatLng = this._shape.getLatLng();
        newRadius = this._shape.getLatLng().distanceTo(e);
        geofenceLayer.setRadius(newRadius);
        geofenceLayer.setLatLng(newLatLng);
      }
    });
  
    // Enable editing on the selected geofence
    this.currentlyEditingGeofence = geofenceLayer;
    geofenceLayer.editing.enable();
    this.saveAndCancelControls(geofenceLayer, newLatLng, newRadius, originalRadius);
   
    // Store the original state of the geofence for cancellation
    this.originalGeofenceState[geofenceLayer._leaflet_id] = {
      type: geofenceLayer instanceof L.Circle ? 'circle' : (geofenceLayer instanceof L.Rectangle ? 'rectangle' : 'polygon'),
      latlngs: geofenceLayer instanceof L.Circle ? [geofenceLayer.getLatLng()] : geofenceLayer.getLatLngs()
    };
    }

    saveAndCancelControls(geofenceLayer: any, newLatLng : any, newRadius: any, originalRadius: any){
    // Create custom save/cancel button control
    const SaveCancelControl = L.Control.extend({
     onAdd: (map: any) => {
       const container = L.DomUtil.create('div', 'leaflet-bar leaflet-control edit-control');
       const saveButton = L.DomUtil.create('button', '', container);
       saveButton.innerHTML = 'Save';
       saveButton.className = 'customBtn';
 
       const cancelButton = L.DomUtil.create('button', '', container);
       cancelButton.innerHTML = 'Cancel';
       cancelButton.className = 'customBtn';
 
       L.DomEvent.on(saveButton, 'click', () => {
         this.saveGeofenceChanges(geofenceLayer);
         if (newRadius) {
           geofenceLayer.setRadius(newRadius);
           geofenceLayer.setLatLng(newLatLng);
         }
         map.removeControl(this.currentlyEditingGeofence.control);
         this.currentlyEditingGeofence = null;
       });
 
       L.DomEvent.on(cancelButton, 'click', () => {
         geofenceLayer.editing.disable();
         this.cancelGeofenceChanges(geofenceLayer);
         if (originalRadius) {
           geofenceLayer.setRadius(originalRadius);
         }
         if (!(geofenceLayer instanceof L.Circle)) {
           geofenceLayer._tooltip.setLatLng(geofenceLayer.getCenter());
         }
         geofenceLayer.openTooltip();
         map.removeControl(this.currentlyEditingGeofence.control);
         this.currentlyEditingGeofence = null;
       });
 
       return container;
     },
   });
 
   // Add the control to the map
   const control = new SaveCancelControl({ position: 'topright' }).addTo(this.map);
   this.currentlyEditingGeofence.control = control;
    }

    // Save geofence changes after editing
    saveGeofenceChanges(geofenceLayer: any) {
        // Handle saving updated geofence coordinates or properties
        geofenceLayer.editing.disable();
        if (!(geofenceLayer instanceof L.Circle)) {
        geofenceLayer._tooltip.setLatLng(geofenceLayer.getCenter());
        }
        geofenceLayer.openTooltip();
    }

    // Cancel geofence changes (revert to original)
    cancelGeofenceChanges(geofenceLayer: any) {
        // Revert the geofence to its original state
        const originalState = this.originalGeofenceState[geofenceLayer._leaflet_id];
        if (originalState) {
        if (originalState.type === 'circle') {
            geofenceLayer.setLatLng(originalState.latlngs[0]);
        } else if (originalState.type === 'rectangle' || originalState.type === 'polygon') {
            geofenceLayer.setLatLngs(originalState.latlngs);
        }
        // Remove the original state from the record
        delete this.originalGeofenceState[geofenceLayer._leaflet_id];
        }
    }

}